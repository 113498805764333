// font
@import url('https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700&display=swap&subset=thai'); // normal=400 bold=700

$font-family-kanit: 'Kanit', sans-serif;

// border main
$border-main: 1px solid #ced4da;

// input
$input-bg: #ffffff;
$input-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
$input-placeholder: rgba(17, 15, 36, 0.4);
$input-placeholder-focus: 1px solid #012b62;
$input-placeholder-disable: 1px solid #ced4da;
$input-placeholder-disable-bg: #f3f3f3;
$input-placeholder-disable-text: #999999;
$input-placeholder-error: 1px solid #ff0000;

// text color
$text-color-white: #ffffff;
$text-color-blue: #002f6c;
$text-color-black: #1b1b1b;
$text-color-red2: #ff0000;
$text-color-gray: #9c9c9c;
$text-color-gray2: #858585;
$text-color-green: #1cbda5;
$text-color-red: #c14d48;

// backgroung color
$background-color-gray: #f6f8fa;

// button
$btn-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
$btn-primary-bg: linear-gradient(360deg, #002F6C 0%, #012A5F 100%);
$btn-primary-bg-hover: #003d8c;
$btn-primary-bg-active: #012657;
$btn-primary-text: #ffffff;

// template error
$template-error-title: rgba(0, 47, 108, 0.05);

// loading
$loading-bg: rgba(0, 0, 0, 0.3);
$loading-box-bg: #e5e5e5;

// tracking status
$tracking-status-detail-bg: #ffffff;
$tracking-status-detail-bg-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
$tracking-status-detail-border-section: 1px solid #f0f0f0;
$tracking-status-border-status: 2px solid #002f6c;
$tracking-status-border-status-unactive: 2px solid #858585;
$tracking-status-border-status-active: 2px solid #1cbda5;
$tracking-status-bg-status-active: #24d0b6;
$tracking-status-bg-status-error: 2px solid #c14d48;
