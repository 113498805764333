@import '../../common/commonConstant.scss';
$primary: #002f6c;
$success: #24d0b6;

.SearchTracking {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  > .box {
    max-width: 540px;
    width: 100%;
    margin: 20px;
    .logo {
      max-width: 180px;
      margin: 0px auto 20px;
      display: block;
      &.active {
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
    .box-back-btn {
      display: none;
      align-items: center;
      margin-bottom: 10px;
      &.active {
        @media (max-width: 576px) {
          display: flex;
        }
      }
      .icon {
        color: $text-color-blue;
        font-size: 24px;
        margin-right: 5px;
        cursor: pointer;
      }
      .text {
        color: $text-color-blue;
        font-size: 24px;
      }
    }
    .sec-info {
      background-color: $tracking-status-detail-bg;
      box-shadow: $tracking-status-detail-bg-shadow;
      border-radius: 5px;
      margin-top: 30px;
      .not-found {
        padding: 16px;
        color: $text-color-red;
      }
    }
    .section-detail {
      .box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        border-bottom: $tracking-status-detail-border-section;
        .left {
          .title {
            font-size: 14px;
            color: $text-color-gray;
          }
          .number {
            font-size: 18px;
            font-weight: 400;
            color: $text-color-blue;
          }
        }
        .right {
          &._delivered {
            .txt {
              color: $success;
            }
          }
          .icon {
            color: $text-color-gray;
          }
        }
      }
      .box-detail {
        padding: 5px 20px 10px;
        .box-customer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            margin-right: 5px;
          }
          .right {
            margin-left: 5px;
          }
        }
        .box-address {
          margin-top: 10px;
        }
        .title {
          font-size: 10px;
          font-weight: 400;
          color: $text-color-blue;
        }
        .description {
          font-size: 14px;
          color: $text-color-black;
        }
      }
    }
    .section-status {
      padding: 15px 20px;
      margin-bottom: 20px;
      .title {
        font-size: 18px;
        color: $text-color-blue;
        font-weight: 400;
        margin-bottom: 10px;
      }
      .box-status {
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: calc(100% / 4);
          position: relative;
          &.active {
            &.red {
              .box-icon {
                border: $tracking-status-bg-status-error;
                .icon {
                  color: $text-color-red;
                  font-size: 18px;
                }
              }
            }
            &.green {
              .box-icon {
                border: $tracking-status-border-status-active;
                background-color: $tracking-status-bg-status-active;
                .icon {
                  color: $text-color-green;
                  font-size: 18px;
                  &.top {
                    position: absolute;
                    color: $text-color-white;
                  }
                }
              }
            }
            .box-icon {
              border: $tracking-status-border-status;
              .icon {
                color: $text-color-blue;
                font-size: 18px;
              }
            }
            .line {
              border: $tracking-status-border-status;
              color: $text-color-blue;
            }
          }
          .box-icon {
            width: 42px;
            height: 42px;
            border: $tracking-status-border-status-unactive;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            background-color: $tracking-status-detail-bg;
            z-index: 1;
            .icon {
              color: $text-color-gray2;
              font-size: 18px;
            }
          }
          .line {
            border: $tracking-status-border-status-unactive;
            position: absolute;
            width: 100%;
            right: -50%;
            top: 21px;
          }
          .status {
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            color: $text-color-black;
            @media (max-width: 575px) {
              font-size: 12px;
            }
          }
          .date {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: $text-color-gray;
            @media (max-width: 575px) {
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}
.ex-order {
  font-size: 12px;
  margin-top: 22px;
  b {
    color: #c33;
  }
}
textarea {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);
  border-radius: 6px;
  padding: 6px 12px;
  &:hover, &:active, &:focus {
    border: 1px solid #ccc;
    outline: none !important;
  }
}
button {
  border: none;
  color: #fff;
  background: linear-gradient(360deg, #002F6C 0%, #012A5F 100%);
  border-radius: 5px;
  padding: 8px 32px;
  width: 70%;
  margin: 18px auto 0 auto;
}
h2 {
  color: $primary;
}
.intransit {
  .intransit-item {
    display: flex;
    margin-bottom: 20px;
    position: relative;
    z-index: 10;
    &:last-child {
      .in-radio {
        &::before {
          content: none;
          width: 0 !important;
          height: 0 !important;
        }
      }
    }
    &.active {
      .in-radio {
        border: 3px solid $success;
        background-color: $success;
      }
      .in-info {
        .in-date {
          color: $success;
        }
      }
      .in-status {
        color: $success;
      }
    }
    .in-radio {
      position: relative;
      border-radius: 50%;
      border: 3px solid $primary;
      background-color: #fff;
      width: 20px;
      height: 20px;
      &::before {
        position: absolute;
        z-index: -1;
        content: '';
        background-color: $primary;
        top: 0;
        left: 6px;
        width: 3px;
        height: 56px;
      }
    }
    .in-info {
      margin-left: 22px;
      .in-date {
        font-size: 14px;
        color: $primary;
      }
      small {
        display: block;
        margin-top: -4px;
        color: #838383;
        font-size: 10px;
      }
    }
    .in-status {
      font-size: 14px;
      margin-left: auto;
    }
  }
}