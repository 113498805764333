@import '../../common/commonConstant.scss';

.InputWithIcon {
  .box-input {
    display: flex;
    align-items: center;
    border-radius: 100px;
    background-color: $input-bg;
    box-shadow: $input-shadow;
    // &.normal {
    //   border: $border-main;
    // }
    // &.focus {
    //   border: $input-placeholder-focus;
    // }
    &.disabled {
      // border: $input-placeholder-disable;
      background-color: $input-placeholder-disable-bg;
      color: $input-placeholder-disable-text;
    }
    &.warning {
      border: $input-placeholder-error;
    }
    .icon {
      color: $text-color-blue;
      font-size: 18px;
      margin-left: 20px;
    }
    input.input {
      border: unset !important;
      box-shadow: unset !important;
      padding-left: 10px;
      padding-right: 20px;
      background-color: transparent;
      border-radius: 100px !important;
    }
  }
  .text-warning {
    font-style: italic;
    font-size: 14px;
    color: $text-color-red2 !important;
    .icon {
      margin-right: 5px;;
    }
  }
}
