@import './common/commonConstant.scss';
@import '~bootstrap/dist/css/bootstrap.min.css';

html {
  body {
    margin: 0px;
    font-family: $font-family-kanit;
    color: $text-color-black;
    font-weight: 300;
    background-color: $background-color-gray;
    #root {
      .App {
        // input
        input {
          height: 49px;
          font-weight: 300;
          border-radius: 4px;
          padding: 10px;
          font-size: 16px;
          &::placeholder {
            color: $input-placeholder;
            font-weight: 300;
          }
          &:focus {
            border: $input-placeholder-focus;
          }
          &:disabled {
            border: $input-placeholder-disable;
            background-color: $input-placeholder-disable-bg;
            color: $input-placeholder-disable-text;
          }
        }
        // button
        .btn {
          font-weight: 400;
          border-radius: 5px;
          box-shadow: $btn-shadow;
          padding: 15px 20px;
          font-size: 18px;
          &.btn-primary {
            background: $btn-primary-bg;
            color: $btn-primary-text;
            border: unset;
            &:hover {
              background: $btn-primary-bg-hover;
            }
            &:active {
              background: $btn-primary-bg-active;
            }
          }
        }
        // template
        .template-error {
          position: relative;
          margin-top: 80px;
          margin-bottom: 30px;
          @media (max-width: 767px) {
            margin-left: 20px;
            margin-right: 20px;
          }
          .box-page {
            text-align: center;
            .logo {
              max-width: 180px;
            }
            .error-title {
              position: absolute;
              top: calc(50% - 330px);
              left: calc(50% - 259.5px);
              font-size: 300px;
              color: $template-error-title;
              @media (max-width: 767px) {
                top: calc(50% - 240px);
                left: calc(50% - 168px);
                font-size: 200px;
              }
            }
            .box-detail {
              margin-top: -94px;
              min-height: calc(100vh - 80px - 30px);
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              .error-icon {
                max-width: 120px;
                margin-bottom: 15px;
                @media (max-width: 767px) {
                  max-width: 76px;
                }
              }
              .error-detail {
                font-size: 36px;
                color: $text-color-blue;
                @media (max-width: 767px) {
                  font-size: 28px;
                }
              }
              .error-description {
                font-size: 20px;
                color: $text-color-gray;
                max-width: 375px;
                margin: 0px auto 65px;
                @media (max-width: 767px) {
                  font-size: 16px;
                }
              }
              .btn {
                width: 100%;
                max-width: 340px;
              }
            }
          }
        }
      }
    }
  }
}
